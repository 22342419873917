<template>
  <div>
    <PageTitle
      title="新增會員權益範本"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'PeriodicBenefitTemplateList' })"
    />

    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <div class="form-block-wrapper">
        <BaseInfoFormBlock :formData.sync="formData" />
        <BenefitFormBlock
          :formData.sync="formData"
        />
        <PeriodFormBlock :formData.sync="formData" />
        <OthersConditionsFormBlock :formData.sync="formData" />
      </div>
    </BaseElForm>

    <PageFixedFooter
      :confirmLoading="loading.submit"
      @confirm="onSubmit"
      @cancel="$router.push({ name: 'PeriodicBenefitTemplateList' })"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, provide, reactive } from 'vue'
import { useBaseForm } from '@/use/useForm'
import BaseInfoFormBlock from './components/BaseInfoFormBlock.vue'
import BenefitFormBlock from './components/BenefitFormBlock.vue'
import PeriodFormBlock from './components/PeriodFormBlock.vue'
import OthersConditionsFormBlock from './components/OthersConditionsFormBlock.vue'
import { set, get } from 'lodash'
import { noEmptyRules } from '@/validation'
import { CreatePeriodicBenefitTemplate, FindPeriodicBenefitTemplate } from '@/api/periodicBenefit'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { useRoute, useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'EditPeriodicBenefitTemplate',
  components: {
    BaseInfoFormBlock,
    BenefitFormBlock,
    PeriodFormBlock,
    OthersConditionsFormBlock,
    PageFixedFooter,
  },
  setup () {
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    const { shopId } = useShop()
    const router = useRouter()
    const route = useRoute()

    initFormData({
      type: 'standard',
      name: null,
      img: null,
      description: null,
      subscribePlan: null,
      period: {
        timeRange: null, // 發送時間範圍 - earlyMorning | morning | afternoon | night

        fixedValue: undefined, // 固定時間數值（最後一天請填 32）
        nDayValue: undefined,
        fixedWeekDay: undefined,

        startType: 'instant', // 週期起始計算類型 - instant | fixed
        type: 'monthly', // 週期類型 -  daily | weekly | monthly | nDay
      },
      totalAwardCount: null, // 總發放次數
      instantAward: false, // 獲得當下是否發放
      failConditionCount: false, // 條件不符合是否計算發放次數
      enableConditions: {
        memberLevel: false,
      },
      conditionsData: {
        memberLevel: [],
      },
      conditions: [], // 其他發放條件 [ type, levels[int] ]
      entitlements: [],
      order: 100,
    })

    const loading = reactive({
      submit: false,
    })

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        order: [noEmptyRules()],
        description: [noEmptyRules()],
        entitlements: [{
          required: true,
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              return callback(new Error('請至少新增一個'))
            } else {
              return callback()
            }
          },
          trigger: ['change', 'blur'],
        }],
        period: {
          timeRange: [noEmptyRules()],
          fixedValue: [noEmptyRules()],
          fixedWeekDay: [noEmptyRules()],
          nDayValue: [noEmptyRules()],
          startType: [noEmptyRules()],
          type: [noEmptyRules()],
        },
        totalAwardCount: [noEmptyRules()],
      }

      for (const idx in formData.entitlements) {
        if (['classTicket', 'pointCard', 'punchCard', 'coupon'].includes(formData.entitlements[idx].type)) {
          set(rules, `entitlements[${idx}].standardAward.awardId`, [noEmptyRules()])
        }
        set(rules, `entitlements[${idx}].standardAward.amount`, [noEmptyRules()])
      }

      return rules
    })

    const submitPayload = computed(() => {
      let fixedValue = formData.period.fixedValue
      const periodStartType = formData.period.startType
      if (periodStartType === 'fixed' && formData.period.type === 'weekly') {
        fixedValue = formData.period.fixedWeekDay
      }

      const conditions = []

      if (formData.enableConditions.memberLevel) {
        conditions.push({
          type: 'level',
          levels: formData.conditionsData.memberLevel,
        })
      }

      return {
        type: formData.type,
        name: formData.name,
        description: formData.description,
        imageId: get(formData.img, 'id'),
        period: {
          startType: periodStartType,
          type: formData.period.type,
          timeRange: formData.period.timeRange,
          nDayValue: formData.period.nDayValue,
          fixedValue,
        },
        totalAwardCount: formData.totalAwardCount,
        instantAward: formData.instantAward,
        failConditionCount: formData.failConditionCount,
        conditions,
        entitlements: formData.entitlements,
      }
    })

    const onSubmit = async () => {
      if (loading.submit) return
      loading.submit = true

      const pass = await checkForm(formRef.value)
      if (!pass) {
        loading.submit = false
        return
      }

      const [, err] = await CreatePeriodicBenefitTemplate({
        shopId: shopId.value,
        ...submitPayload.value,
      })
      loading.submit = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.createSuccess)
      router.push({ name: 'PeriodicBenefitTemplateList' })
    }

    const copyPeriodicBenefitTemplate = async () => {
      loading.value = true
      const [res, err] = await FindPeriodicBenefitTemplate({
        shopId: shopId.value,
        id: route.params.id,
      })

      loading.value = false

      if (err) {
        window.$message.error(err)
        return
      }

      formData.type = res.type
      formData.name = res.name
      formData.description = res.description
      formData.order = res.order
      formData.instantAward = res.instantAward
      formData.totalAwardCount = res.totalAwardCount
      formData.failConditionCount = res.failConditionCount
      // period
      formData.period.timeRange = res.period.timeRange

      formData.period.nDayValue = res.period.nDayValue
      formData.period.startType = res.period.startType
      formData.period.type = res.period.type
      if (res.period.type === 'fixed' && res.period.startType === 'weekly') {
        formData.period.fixedWeekDay = res.period.fixedValue
      } else {
        formData.period.fixedValue = res.period.fixedValue
      }

      const levelConditions = res.conditions.find((condition) => condition.type === 'level')
      if (levelConditions) {
        formData.enableConditions.memberLevel = true
        formData.conditionsData.memberLevel = levelConditions.levels
      }
    }

    provide('formData', formData)

    onBeforeMount(() => {
      const isCopy = route.query.isCopy
      if (isCopy) copyPeriodicBenefitTemplate()
    })

    return {
      formRef,
      formData,
      formRules,
      initFormData,
      loading,
      onSubmit,
      submitPayload,
    }
  },
})

</script>

<style lang="postcss" scoped>
.form-block-wrapper {
  @apply flex flex-col gap-[16px];
}
</style>
