<template>
  <div class="section-block">
    <p class="text-primary-100 font-medium text-md pb-[12px]">其他發放條件</p>

    <template v-if="syncFormData.type === 'subscription'">
      <BaseElFormItem label="訂閱扣款成功才會發放">
        <GrayBlockContainer>
          欲變更設定，請至「基本參數模組 > 會員權益參數設定 > 訂閱制權益發放設定」
        </GrayBlockContainer>
      </BaseElFormItem>
    </template>

    <BaseElFormItem v-if="syncFormData.type === 'standard'" style="width: 560px;">
      <template #label>
        <div class="w-full flex items-center justify-between">
          <p>發放時的會員等級</p>
          <BaseElCheckbox v-model="syncFormData.enableConditions.memberLevel">
            啟用
          </BaseElCheckbox>
        </div>
      </template>
      <div>
        <p>會員只需符合下方所選的其中一個會員等級</p>
        <BaseElSelect
          v-model="syncFormData.conditionsData.memberLevel"
          :disabled="!syncFormData.enableConditions.memberLevel"
          multiple
          collapse-tags
        >
          <BaseElSelectOption
            v-for="level in memberLevels"
            :key="level.value"
            :label="level.label"
            :value="level.value"
          />
        </BaseElSelect>
      </div>
    </BaseElFormItem>

    <BaseElFormItem prop="failConditionCount">
      <template #label>
        <FormItemTooltipLabel
          label="發放會員權益時，如會員不符條件，仍扣除發放次數"
          :tooltipWidth="200"
        >
          會員不符合發放條件就不會實際發放權益項目，但可以扣除發放次數
        </FormItemTooltipLabel>
      </template>
      <BaseElSwitch
        v-model="syncFormData.failConditionCount"
        active-text="是"
        inactive-text="否"
      />
    </BaseElFormItem>
  </div>
</template>

<script>
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useMemberLevel } from '@/use/memberLevel'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useVModel } from '@vueuse/core'
import { map } from 'lodash'

export default defineComponent({
  name: 'OthersConditionsFormBlock',
  components: {
    FormItemTooltipLabel,
    GrayBlockContainer,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const syncFormData = useVModel(props, 'formData', emit)
    const memberLevels = computed(() => {
      return map(memberLevelConfig.value, (item) => ({
        label: item.name,
        value: item.level,
      }))
    })
    const { getMemberLevelConfig, memberLevelConfig } = useMemberLevel()

    onBeforeMount(() => {
      getMemberLevelConfig()
    })

    return { syncFormData, memberLevels }
  },
})

</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__label {
  @apply w-full;
}
</style>
